/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Convergence 2024 by Ferretti Group: Exploring the Future of Yachting"), "\n", React.createElement(_components.h2, null, "Overview"), "\n", React.createElement(_components.p, null, "Convergence 2024 by Ferretti Group is an exclusive event that gathers over 130 captains and chief engineers of Ferretti Group ships and yachts, alongside technical partners and experts. Held in Madrid, the event delves into crucial topics such as innovation, artificial intelligence, and new technologies, all of which are key areas of focus for Ferretti Group. The primary objective of the event is to delve into the latest advancements and trends within the yachting industry."), "\n", React.createElement(_components.p, null, "For more information, visit the following links:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ferrettigroup.com/en-us/News-and-events/Detail/t/Convergence-2024-by-Ferretti-Group-in-Madrid-to/y/2024/n/2299"
  }, "Convergence 2024 by Ferretti Group - Ferretti Group Website")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ferrettigroupamerica.com/en-us/Services/Convergence"
  }, "Convergence - Ferretti Group America")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sail-world.com/photo/461369"
  }, "Convergence 2024 by Ferretti Group - Sail-World.com")), "\n"), "\n", React.createElement(_components.p, null, "Kindly note that the details provided are sourced from available information and are subject to potential changes."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
